import { displayMsg, displayResult } from "./DisplayMsg";
import { moveBar } from "./MoveBar";
import Bowser from "bowser";
import {
  chromeEdgeMinVerMsg,
  iosNotWebBleMsg,
  otherOsNotChromeEdgeMsg,
} from "./messages";
import { minChromeEdgeVer } from "./constants";

export const testBrowser = (width: number) => {
  displayMsg("Testing your browser...", "success");

  let browserRes = {
    osName: '',
    message: ''
  };

  const browser = Bowser.getParser(window.navigator.userAgent);
  const osName = browser.getOSName();
  const browserName = browser.getBrowserName();
  const browserVersion = browser.getBrowserVersion();

  if (osName === "iOS") {
    const isWebBle = window.navigator.userAgent
      .toLowerCase()
      .includes("webble/");
    const isBluefy = window.navigator.userAgent
      .toLowerCase()
      .includes("bluefy/");
    if (!isWebBle && !isBluefy) {
      browserRes.message = iosNotWebBleMsg;
    }
    browserRes.osName = osName;
  } else {
    if (browserName === "Microsoft Edge" || browserName === "Chrome") {
      const browserVer = Number(browserVersion.split(".")[0]);
      if (browserVer >= minChromeEdgeVer) {
        moveBar(width);
      } else {
        browserRes.message = chromeEdgeMinVerMsg;
      }
    } else {
      browserRes.message = otherOsNotChromeEdgeMsg;
    }
  }
  moveBar(width);
  displayResult(browserRes.message);
  return browserRes;
};
