import { bluetoothFilters, optionalServices } from "./constants";
import { displayMsg, displayResult } from "./DisplayMsg";
import {
  bluetoothRequestCancelledMsg,
  bluetoothSecurityMsg,
  noBluetoothDeviceFoundMsg,
} from "./messages";
import { moveBar } from "./MoveBar";
export const testBluetooth = async (width, osName) => {
  displayMsg(
    "Testing bluetooth capabilities.. please wait and select your device",
    "success"
  );
  try {
    const filters = bluetoothFilters;
    const optServices = optionalServices;
    if (osName === "iOS") {
      for (let i = filters.length - 1; i >= 0; i--) {
        const filter = filters[i];
        if (filter.services !== undefined) {
          filters.splice(i, 1);
          optServices.push(...filter.services);
        }
      }
    }
    const device = await navigator.bluetooth.requestDevice({
      filters: filters,
      optionalServices: optServices
    });
  } catch (error) {
    let message = error.message;
    if (message === "User cancelled the requestDevice() chooser.") {
      message = bluetoothRequestCancelledMsg;
    } else if (
      message === "User or their enterprise policy has disabled Web Bluetooth."
    ) {
      message = bluetoothSecurityMsg;
    } else {
      message = noBluetoothDeviceFoundMsg;
    }
    displayResult(message);
  }
  moveBar(width);
};
